import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #f8fafc;
  padding: 2.5rem 1.5rem;
  text-align: center;
  margin-top: 2rem;
  border-top: 1px solid #e2e8f0;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterLinks = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
`;

const FooterLink = styled(Link)`
  color: #3182ce;
  text-decoration: none;
  margin: 0 1rem;
  font-weight: 500;
  transition: color 0.2s ease;
  
  &:hover {
    color: #2c5282;
    text-decoration: underline;
  }
`;

const Copyright = styled.p`
  color: #718096;
  font-size: 0.9rem;
`;

const AffiliateDisclaimer = styled.p`
  color: #718096;
  font-size: 0.85rem;
  margin-top: 1rem;
  max-width: 800px;
  line-height: 1.5;
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <FooterContainer>
      <FooterContent>
        <FooterLinks>
          <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
          <FooterLink to="/terms-of-service">Terms of Service</FooterLink>
        </FooterLinks>
        <Copyright>
          © {currentYear} Choose Wise. All rights reserved.
        </Copyright>
        <AffiliateDisclaimer>
          Disclosure: Some of the links on this website are affiliate links. This means if you click on the link and purchase the item, we may receive a commission at no additional cost to you. We only recommend products we believe will add value to our readers.
        </AffiliateDisclaimer>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;