import React from 'react';
import styled from 'styled-components';

const GuideContainer = styled.section`
  max-width: 1200px;
  margin: 3rem auto;
  padding: 2.5rem;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const GuideTitle = styled.h2`
  color: #2d3748;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 600;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 1rem;
`;

const GuideSection = styled.div`
  margin-bottom: 2.5rem;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionTitle = styled.h3`
  color: #374151;
  margin-bottom: 1.2rem;
  font-size: 1.5rem;
  font-weight: 600;
  position: relative;
  padding-left: 1rem;
  
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.4rem;
    height: 70%;
    width: 4px;
    background-color: #3182ce;
    border-radius: 4px;
  }
`;

const SectionContent = styled.p`
  color: #4a5568;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  font-size: 1.05rem;
`;

const FeatureList = styled.ul`
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
  line-height: 1.8;
`;

const FeatureItem = styled.li`
  margin-bottom: 1rem;
  color: #4a5568;
  position: relative;
  padding-left: 0.5rem;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  strong {
    color: #2d3748;
  }
`;

const FaqContainer = styled.div`
  background-color: #f9fafc;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  border-left: 4px solid #3182ce;
  
  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }
  
  transition: box-shadow 0.2s ease;
`;

const FaqQuestion = styled.div`
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
`;

const FaqAnswer = styled.div`
  color: #4a5568;
  line-height: 1.7;
`;

const ProductGuide = ({ guideData }) => {
  if (!guideData) return null;
  
  return (
    <GuideContainer>
      <GuideTitle>{guideData.guideTitle}</GuideTitle>
      
      {guideData.sections.map((section, sectionIndex) => (
        <GuideSection key={sectionIndex}>
          <SectionTitle>{section.title}</SectionTitle>
          {section.content && <SectionContent>{section.content}</SectionContent>}
          
          {section.features && (
            <FeatureList>
              {section.features.map((feature, featureIndex) => (
                <FeatureItem key={featureIndex}>
                  {feature.title && <strong>{feature.title}:</strong>} {feature.content}
                </FeatureItem>
              ))}
            </FeatureList>
          )}
          
          {section.faqs && section.faqs.map((faq, faqIndex) => (
            <FaqContainer key={faqIndex}>
              <FaqQuestion>Q: {faq.question}</FaqQuestion>
              <FaqAnswer>A: {faq.answer}</FaqAnswer>
            </FaqContainer>
          ))}
        </GuideSection>
      ))}
    </GuideContainer>
  );
};

export default ProductGuide;