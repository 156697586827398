/**
 * This file maps URL slugs to their corresponding data files.
 * To add a new product category, add a new entry to this mapping.
 */
import { categoryFullData as noiseCancelingHeadphonesData } from './noisecanceling_headphones';
import { categoryFullData as tireAirCompressorsData } from './tire_air_compressors';

export const categoryMapping = {
  "tire-inflators": tireAirCompressorsData,
  "headphones": noiseCancelingHeadphonesData
  
};

export const getAllCategories = () => {
  return Object.entries(categoryMapping).map(([slug, data]) => ({
    slug,
    displayName: data.displayName
  }));
};
