import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.main`
  flex: 1;
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem 1rem;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 2rem;
`;

const PrivacyContent = styled.div`
  h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  
  ul, ol {
    margin-left: 2rem;
    margin-bottom: 1rem;
  }
`;

const TermsOfService = () => {
  const [termsContent, setTermsContent] = useState('');

  useEffect(() => {    
    // Fetch the terms_of_use.html content
    fetch('/terms_of_use.html')
      .then(response => response.text())
      .then(data => {
        setTermsContent(data);
      })
      .catch(error => {
        console.error('Error loading terms of service:', error);
      });
  }, []);

  return (
    <PageContainer>
      <Header categoryName="Terms of Service" showYear={false} />
      <ContentContainer>
      {termsContent ? (
          <PrivacyContent dangerouslySetInnerHTML={{ __html: termsContent }} />
        ) : (
          <p>Loading privacy policy...</p>
        )}
      </ContentContainer>
      <Footer />
    </PageContainer>
  );
};

export default TermsOfService;
