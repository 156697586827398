import React from 'react';
import styled from 'styled-components';

const TableContainer = styled.div`
  max-width: 1200px;
  margin: 2rem auto;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  overflow: hidden;
  background: #fff;
  transition: transform 0.3s ease;
  
  @media (max-width: 768px) {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transform: none;
    &:hover {
      transform: none;
    }
  }
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const ScrollableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS devices */
  
  /* Hint for mobile users */
  @media (max-width: 768px) {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 20px;
      background: linear-gradient(to right, rgba(255,255,255,0), rgba(248,250,252,0.7));
      pointer-events: none;
    }
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  min-width: 650px; /* Ensure table has minimum width for proper display */
`;

const TableHeader = styled.th`
  padding: 1.5rem 1rem;
  background-color: #f8fafc;
  text-align: center;
  border-bottom: 2px solid #e9ecef;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9fafc;
  }
  
  &:hover {
    background-color: #f0f7ff;
  }
  
  transition: background-color 0.2s ease;
`;

const TableCell = styled.td`
  padding: 1.2rem;
  text-align: center;
  border-bottom: 1px solid #e9ecef;
  font-size: 1rem;
`;

const ProductImage = styled.img`
  width: 170px;
  height: 170px;
  object-fit: contain;
  margin-bottom: 1rem;
  padding: 0.5rem;
  transition: transform 0.3s ease;
  
  @media (max-width: 1024px) {
    width: 150px;
    height: 150px;
  }
  
  @media (max-width: 768px) {
    width: 130px;
    height: 130px;
  }
  
  @media (max-width: 480px) {
    width: 110px;
    height: 110px;
  }
  
  &:hover {
    transform: scale(1.05);
  }
`;

const ProductImageLink = styled.a`
  display: inline-block;
  cursor: pointer;
`;

const ProductBrand = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.7rem;
  color: #2d3748;
  text-transform: capitalize;
`;

const Rating = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.8rem;
`;

const StarsContainer = styled.div`
  display: flex;
  margin: 0.3rem 0;
`;

const Star = styled.span`
  color: ${props => props.filled ? '#FFB800' : '#d1d5db'};
  margin: 0 1px;
  font-size: 1.2rem;
`;

const RatingNumber = styled.span`
  font-weight: bold;
  color: #2d3748;
  font-size: 1.1rem;
`;

const RatingCount = styled.span`
  color: #6b7280;
  font-size: 0.9rem;
  margin-top: 0.2rem;
`;

const FeatureLabel = styled.td`
  padding: 1.2rem 1.5rem;
  font-weight: 600;
  background-color: #f8fafc;
  border-bottom: 1px solid #e9ecef;
  color: #374151;
  text-transform: capitalize;
  min-width: 150px;
`;

const AmazonButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  background-color: #FF9900;
  color: #000;
  padding: 0.9rem 1.4rem;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.1);
  
  &:hover {
    background-color: #F0800C;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  &:active {
    transform: translateY(0);
  }
`;

const DisclaimerText = styled.div`
  font-size: 0.85rem;
  color: #6b7280;
  text-align: center;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
  background-color: #f8fafc;
  font-style: italic;
`;

// Star rating component
const StarRating = ({ rating }) => {
  const stars = [];
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 >= 0.5;
  
  // Add filled stars
  for (let i = 0; i < fullStars; i++) {
    stars.push(<Star key={`star-${i}`} filled>★</Star>);
  }
  
  // Add half star if needed
  if (hasHalfStar) {
    stars.push(<Star key="half-star" filled>★</Star>);
  }
  
  // Add empty stars
  const emptyStarsCount = 5 - stars.length;
  for (let i = 0; i < emptyStarsCount; i++) {
    stars.push(<Star key={`empty-${i}`}>★</Star>);
  }
  
  return <StarsContainer>{stars}</StarsContainer>;
};

const ComparisonTable = ({ products }) => {
  // Get all unique product properties excluding id, brand, image, rating info, and amazon_link
  const getProductProperties = () => {
    const properties = new Set();
    
    products.forEach(product => {
      Object.keys(product).forEach(key => {
        if (!['id', 'brand', 'image', 'rating', 'rating_count', 'amazon_link'].includes(key)) {
          properties.add(key);
        }
      });
    });
    
    return Array.from(properties);
  };

  const properties = getProductProperties();

  return (
    <TableContainer>
      <ScrollableContainer>
        <Table>
          <thead>
            <tr>
              <TableHeader></TableHeader>
              {products.map(product => (
                <TableHeader key={product.id}>
                  <ProductImageLink 
                    href={product.amazon_link} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    <ProductImage src={product.image} alt={product.brand} />
                  </ProductImageLink>
                  <ProductBrand>{product.brand}</ProductBrand>
                  <Rating>
                    <StarRating rating={product.rating} />
                    <RatingNumber>{product.rating}</RatingNumber>
                    <RatingCount>({product.rating_count.toLocaleString()} reviews)</RatingCount>
                  </Rating>
                </TableHeader>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Add "View Item" row with Amazon buttons */}
            <TableRow>
              <FeatureLabel>View Item</FeatureLabel>
              {products.map(product => (
                <TableCell key={`${product.id}-amazon`}>
                  <AmazonButton 
                    href={product.amazon_link} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    View On Amazon
                  </AmazonButton>
                </TableCell>
              ))}
            </TableRow>
            {properties.map(property => (
              <TableRow key={property}>
                <FeatureLabel>{property.replace(/_/g, ' ')}</FeatureLabel>
                {products.map(product => (
                  <TableCell key={`${product.id}-${property}`}>
                    {product[property]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </tbody>
        </Table>
      </ScrollableContainer>
      <DisclaimerText>
        Disclaimer: Product ratings and review counts shown are not updated in real-time and may not reflect current data on retailer websites.
      </DisclaimerText>
    </TableContainer>
  );
};

export default ComparisonTable;