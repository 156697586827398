import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeaderContainer = styled.header`
  background-color: #ffffff;
  color: #2d3748;
  padding: 1.8rem 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, #3182ce, #63b3ed);
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: -0.5px;
`;

const Subtitle = styled.p`
  color: #4a5568;
  margin-top: 0.5rem;
  font-size: 1.1rem;
`;

// Removed HomeLink styled component and its usage in the component

const Header = ({ categoryName, showYear = true }) => {
  // Get current year
  const currentDate = new Date();
  const year = currentDate.getFullYear();

  return (
    <HeaderContainer>
      <Title>
        {showYear ? `${categoryName}` : categoryName}
      </Title>
      {showYear && <Subtitle>Top Picks for {year}</Subtitle>}
    </HeaderContainer>
  );
};

export default Header;