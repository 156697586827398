import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ComparisonTable from '../components/ComparisonTable';
import ProductGuide from '../components/ProductGuide';
import { categoryMapping } from '../data/categoryMapping';
import useSEO from '../hooks/useSEO';

const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.main`
  flex: 1;
  padding: 0 1rem;
`;

const ComparisonPage = () => {
  const { categorySlug } = useParams();

  const categoryData = categoryMapping[categorySlug];

  const { categoryName, productsToCompare, productGuide } = categoryData;
  const brandNames = productsToCompare.map(product => product.brand).join(', ');
  
  // Use SEO hook
  useSEO({
    title: `${categoryName} compare - Top Picks for ${new Date().getFullYear()}`,
    description: `Compare the best ${categoryName.toLowerCase()} including ${brandNames}. Features, battery life, and power options compared to help you make the best choice.`,
    keywords: `${categoryName}, ${brandNames}, body trimmer comparison, grooming tools, men's grooming, best body trimmers`,
    canonical: `https://yourwebsite.com/${categoryName.toLowerCase().replace(/\s+/g, '-')}`
  });
    return (
      <PageContainer>
        <Header categoryName={categoryName} showYear={true} />
        <ContentContainer>
          <ComparisonTable products={productsToCompare} />
          <ProductGuide guideData={productGuide} />
        </ContentContainer>
        <Footer />
      </PageContainer>
    );
};

export default ComparisonPage;