import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import ComparisonPage from './pages/ComparisonPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import { getAllCategories } from './data/categoryMapping';
import { Helmet } from 'react-helmet';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  body {
    background-color: #ffffff;
    color: #333333;
  }
`;

function App() {
  // Default structured data
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Choose Wise",
    "description": "Compare the best products across multiple categories",
    "url": "https://yourwebsite.com"
  };

  const categories = getAllCategories();
  const defaultCategory = categories.length > 0 ? categories[0].slug : null;

  return (
    <Router>
      <GlobalStyle />
      <Helmet>
        <link rel="icon" href="%PUBLIC_URL%/logo.png" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Routes>
        <Route path="/" element={defaultCategory ? <Navigate to={`/${defaultCategory}`} /> : <div>No categories available</div>} />
        <Route path="/:categorySlug" element={<ComparisonPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
    </Router>
  );
}

export default App;